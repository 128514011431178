import styled from 'styled-components';
import { screenMd, screenSm } from 'constants/styles/breakpoints';

export const TableWrapper = styled.div<{ loading?: boolean }>`
  ${({ loading }) =>
    loading &&
    `
    min-height: 300px;`}

  .ps-4 {
    padding-left: 0 !important;
  }
`;

export const TableLabel = styled.div`
  color: var(--Business-Primary-Color, #1a2535);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const Separator = styled.div`
  border-top: 1px solid var(--bright-gray-lighter);
  width: 100%;
`;

export const OptionsSection = styled.section<{ lock?: boolean }>`
  display: flex;
  flex: 1;

  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  gap: 5px;

  ${({ lock }) =>
    lock &&
    `
    opacity: 0.3;
    pointer-events:none;`}

  ${screenSm} {
    justify-content: space-between;
    padding: 0;
    margin-bottom: 0;
    min-height: 44px;
  }
`;

export const EntitiesWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  ${screenSm} {
    display: flex;
    padding: 5px 10px;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const EntityButton = styled.button<{ active: boolean }>`
  height: 24px;
  padding: 5px 10px;
  margin-right: 20px;
  white-space: nowrap;
  border-style: none;
  background: transparent;
  outline-color: transparent;

  span {
    vertical-align: middle;
  }
  color: var(--black-dark, #1a2535);

  &:hover {
    height: 24px;
    padding: 5px 10px;
    white-space: nowrap;
    border-style: none;
    font-weight: 500;
    background: #f7f7f9;
    border-radius: 10px;
  }

  ${({ active }) =>
    active &&
    `
    color: var(--Business-Business-White, #FFF);
		font-weight: 700;
		background: var(--black-darkest, #111A27);
		border-radius: 10px;

    &:hover {
      background: var(--light-gray-darker, #57575c);
      }
		`}

  ${screenSm} {
    margin-right: 0;
  }
`;

export const TableContainer = styled.section<{ lock?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 140px;
  padding-left: 10px;
  padding-right: 10px;

  .ps-4 {
    padding-left: 0 !important;
  }
  .dropdown.dropup {
    .dropdown-toggle.bg-light {
      width: 81px;
      height: 44px;
      border-radius: 10px !important;
      padding: 10px;
      gap: 30px;
      display: flex;
      align-items: center;
    }
  }

  ${screenMd} {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  ${({ lock }) =>
    lock &&
    `
  opacity: 0.3;
  pointer-events:none;
  min-height: 300px;`}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LetterAvatar = styled.div<{ color: any; card: boolean }>`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 6px;
  background: ${({ color }) => color.lighter};
  color: ${({ color }) => color.base};

  ${({ card }) =>
    card &&
    `
    width: 132px !important;
    height: 132px !important;
    border-radius: 86px !important;
    position: absolute;
    left: 50%;
    fon-size: 70px;
    transform: translateX(-50%);
  `}
`;

export const CardLetterAvatar = styled.div<{ color: any; card: boolean }>`
  width: 132px !important;
  height: 132px !important;
  border-radius: 86px !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const UserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
`;

export const EmptyTable = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UnderConstruction = styled.div`
  display: flex;
  padding: 0px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;
export const UnderConstructionState = styled.img`
  max-height: 350px;
`;
export const SectionUnderConstruction = styled.div`
  display: flex;
  padding: 0px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;

  position: relative;
  top: -68px;

  h1 {
    color: var(--black-dark, #1a2535);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
  }

  p {
    color: var(--licht-gray-darker, #57575c);
    font-size: 17px;
    font-weight: 450;
    line-height: 20px;
    margin-bottom: 0;
    text-align: center;
    max-width: 550px;
  }

  span {
    text-transform: capitalize;
  }
`;

export const EnitiesTableDetails = {
  Background: styled.div`
    background-color: #f2f2f7;
    height: 100%;
  `,
  Header: styled.div<{ bottom?: string }>`
    background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-flow: column;
    margin-bottom: ${({ bottom }) => (bottom ? bottom : '130px')};
  `,
  Title: styled.div`
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin: 0 0 10px 10px;
    max-width: 95%;
    overflow: visible;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .entities-table__table-body-row {
      flex-direction: column;
      position: relative;
      display: block;
      overflow: visible;
      height: 100px;
      top: 25%;
      img {
        width: 132px !important;
        height: 132px !important;
        border-radius: 86px !important;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      .users-column0,
      .teams-column0 {
        &__value {
          position: absolute;
          bottom: -86px;
          left: 50%;
          transform: translateX(-50%);
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;

          text-align: center;
          color: #1a2535;

          padding-left: 0;
        }

        &__scnd {
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 20px;
          color: #57575c;
        }
      }
      .teams-column0 {
        &__value {
          bottom: -70px;
        }
      }
    }
  `,
  Content: styled.div`
    margin: 20px 15px;
    height: 80%;
  `,
  Row: {
    Container: styled.div`
      background-color: #fff;
      height: 45px;
      display: grid;
      grid-template-columns: 85% 1px 15%;
      margin-bottom: 10px;
    `,
    Title: styled.div`
      color: #57575c;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      margin-left: 20px;
      align-self: center;
    `,
    Separator: styled.div`
      width: 1px;
      height: 100%;
      border: #e5e5e5 solid 1px;
    `,
    Value: styled.div`
      color: #1a2535;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      align-self: center;
    `,
  },
};
