import {
  ContentList,
  GroupItemTitle,
  GroupTitleLearningPath,
  LearningPathTopicContainer,
  PlaylistTopicContainer,
  ProgressContainer,
  RowContent,
  TopicTitle,
  UserRow,
  UsersRowContainer,
} from './styles';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
  IPlaylist,
  IPlaylistGroup,
  IPlaylistUserDetails,
  IPlaylistUserListItemResponse,
} from 'contexts/playlists/types';

import Avatar from './Avatar';
import ButtonIcon from 'components/buttonIcon';
import { Cell } from 'components/Table';
import ContentProgressBar from './ContentProgressBar';
import ContentTypeTag from 'pages/Playlists/components/AddContent/ContentTypeTag';
import DonutProgressBar from 'components/donutProgressBar';
import GroupItemProgress from './GroupItemProgress';
import Icon from 'components/icons';
import Notification from 'components/notification';
import ProgressScore from 'components/progressScore';
import { SessionContext } from 'contexts/session';
import { Spinner } from 'components/loadings';
import Tooltip from 'components/tooltip';
import { formatDistanceToNow } from 'date-fns';
import { getPlaylistUserDetails } from 'services/playlists';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

interface IUserRowParams {
  item: IPlaylistUserListItemResponse;
  playlist: IPlaylist;
  removeUser: (user: IPlaylistUserListItemResponse) => void;
  isSonar: boolean;
}

interface IContentProps {
  content: any;
  embeddedContent?: boolean;
}

const ContentRow = ({ content, embeddedContent }: IContentProps) => {
  return (
    <RowContent embeddedContent={embeddedContent}>
      <div className="content-type-tag-container">
        <ContentTypeTag
          value={content.content_type}
          is_lab_experience={content.is_lab_experience}
          is_trackable={content.is_trackable}
        />
      </div>
      <GroupItemTitle>{content.name}</GroupItemTitle>
      <ContentProgressBar item={content} />
    </RowContent>
  );
};

const ProgressCell = styled(Cell)`
  display: flex;
  gap: 10px;
`;

interface IPlaylistTopicProps {
  data: IPlaylistGroup;
}

const PlaylistTopic: React.FC<IPlaylistTopicProps> = ({ data }) => (
  <PlaylistTopicContainer>
    <TopicTitle>{data.name}</TopicTitle>
    <ContentList>
      {data.content.map((content, index) => (
        <Fragment key={index}>
          <ContentRow content={content} />
          {content.content_type === 'learning-path' &&
            content.content.map((learningPathTopic, index) => (
              <LearningPathTopicContainer key={index}>
                <GroupTitleLearningPath>{learningPathTopic.name}</GroupTitleLearningPath>
                <ContentList>
                  {learningPathTopic.content.map((content, index) => (
                    <ContentRow key={index} content={content} embeddedContent />
                  ))}
                </ContentList>
              </LearningPathTopicContainer>
            ))}
        </Fragment>
      ))}
    </ContentList>
  </PlaylistTopicContainer>
);

const UsersRow: React.FC<IUserRowParams> = ({ item, playlist, removeUser, isSonar }) => {
  const { account } = useContext(SessionContext);
  const [showDetails, setShowDetails] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [userDetails, setUserDetails] = useState<IPlaylistUserDetails | null>(null);

  let columnsTableConfig;
  if (isMobile) {
    columnsTableConfig = 'auto 100px';
  } else {
    columnsTableConfig = '6fr 3fr 3fr 3fr 3fr';
  }

  const onRemoveUser = (item: IPlaylistUserListItemResponse) => {
    removeUser(item);
  };

  const toggleDetails = async () => {
    if (showDetails) {
      setShowDetails(false);
      setUserDetails(null);
    } else {
      setShowDetails(true);
      setFetching(true);
      try {
        const response = await getPlaylistUserDetails({
          business_account_id: account.id,
          playlist_id: playlist.id,
          user_id: item.user.id,
        });
        if (response.status === 200) {
          const data = response.data;
          response.status === 200 && setUserDetails(data);
        }
      } catch (error) {
        Notification({ text: 'There has been a problem', type: 'error' });
      } finally {
        setFetching(false);
      }
    }
  };

  useEffect(() => {
    if (isSonar) toggleDetails();
  }, [isSonar]);

  return (
    <UsersRowContainer>
      <UserRow columns={columnsTableConfig}>
        <Cell>
          <Avatar user={item.user} />
        </Cell>
        {!isMobile && (
          <>
            <ProgressCell>
              <DonutProgressBar progress={item.progress} />
              <div>
                <div>
                  {item.trackable_items_finished}/{item.trackable_items_total}
                </div>
                <div>{item.progress * 100}% complete</div>
              </div>
            </ProgressCell>
            <Cell>
              {item.items_started}/{item.items_total}
            </Cell>
            <Cell>
              {item.last_activity_date &&
                formatDistanceToNow(new Date(item.last_activity_date), { addSuffix: true })}
            </Cell>
          </>
        )}
        <Cell style={{ justifyContent: 'flex-end', gap: '10px' }}>
          <Tooltip placement="bottomRight" title={'Remove user from playlist'}>
            <ButtonIcon
              $secondary
              size={'small'}
              icon={'Trash'}
              onClick={() => onRemoveUser(item)}
            />
          </Tooltip>
          <Tooltip placement="bottomRight" title={'Show progress details'}>
            <div style={{ cursor: 'pointer', padding: '8px' }}>
              <Icon
                size="small"
                color="#A793F6"
                icon={showDetails ? 'ChevronUp' : 'ChevronDown'}
                onClick={toggleDetails}
              />
            </div>
          </Tooltip>
        </Cell>
      </UserRow>
      <ProgressContainer visible={showDetails} transparent={isMobile} topSelect={false} top={false}>
        {fetching ? (
          <Spinner active size="50" position="relative" top="0px" />
        ) : (
          userDetails?.content.map((playlistTopic, index) => (
            <PlaylistTopic key={index} data={playlistTopic} />
          ))
        )}
      </ProgressContainer>
    </UsersRowContainer>
  );
};

export default UsersRow;
