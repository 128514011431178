import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';
import { Divider, Dropdown } from 'antd';
import { Fragment, useContext, useEffect, useState } from 'react';
import { IconContainer, TeamsDropdownCTA, TeamsMenuContainer, TeamsMenuItem } from './styles';
import Tooltip from 'components/tooltip';
import Button from 'components/button/Button';
import { IconTeam } from './IconTeam';
import { Team, UserTeam } from 'contexts/teams/types';
import { SessionContext } from 'contexts/session';
import { AccountSummary } from 'types/users';
import Checkbox from 'components/checkbox';
import Icon from 'components/icons';
import {
  accountOwnerDefaultOption,
  noTeamOption,
  removeOption,
  teamManagerDefaultOption,
} from 'constants/components/teamSelect';
import { isEqual } from 'lodash';

interface TeamsSelectorProps {
  onTeamChange: (teamIds: string[]) => void;
  withSubmit?: boolean;
  accountTeams: Team[];
  withEditBtn?: boolean;
  emptyLabel?: string;
  editBtnRight?: boolean;
  maxWidth?: string;
  userTeams?: UserTeam[];
}

interface TeamsMenuProps {
  onClose(): void;
  onTeamSelect(teams: string[]): void;
  withSubmit?: boolean;
  accountTeams: Team[];
  account?: AccountSummary;
  full?: boolean;
  emptyLabel: string;
  selectAllOption?: string;
  fromInvites?: boolean;
  teamsSelected?: UserTeam[];
  teamRemoved?: string;
  analyticsTheme?: boolean;
}

const CustomDropdown = styled(Dropdown)<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const TeamsMenu = ({
  onClose,
  onTeamSelect,
  withSubmit,
  accountTeams,
  teamsSelected,
  account,
  full,
  selectAllOption,
  teamRemoved,
  analyticsTheme,
}: TeamsMenuProps): JSX.Element => {
  const { isAccountOwner } = useContext(SessionContext);
  const [selected, setSelected] = useState<string[]>([]);
  const [prevSelected, setPrevSelected] = useState([]);

  const selectAllLabel = analyticsTheme
    ? selectAllOption
      ? selectAllOption
      : isAccountOwner
      ? accountOwnerDefaultOption
      : teamManagerDefaultOption
    : removeOption;

  const handleTeamSelection = (id: string) => {
    const currentSelected = selected;
    setSelected((prevState) => {
      if (id === noTeamOption && !prevState.length) {
        return prevState;
      }
      if (id === noTeamOption) {
        return [];
      }
      if (prevState.includes(id)) {
        return prevState.filter((ps) => ps !== id);
      } else {
        return [...prevState, id];
      }
    });
    setPrevSelected(currentSelected);
  };

  const handleTeamChangeSubmit = () => {
    onTeamSelect(selected);
    setPrevSelected(selected);
    handleClose();
  };

  const handleClose = () => {
    setSelected(prevSelected);
    onClose();
  };

  useEffect(() => {
    if (teamsSelected && teamsSelected.length > 0) {
      const currentTeams = teamsSelected.reduce((acc, team) => {
        acc.push(team.team_id);
        return acc;
      }, []);
      setSelected(currentTeams);
      setPrevSelected(currentTeams);
    }
  }, [teamsSelected]);

  useEffect(() => {
    withSubmit && !isEqual(prevSelected, selected) && onTeamSelect(selected);
  }, [selected]);

  useEffect(() => {
    teamRemoved && handleTeamSelection(teamRemoved);
  }, [teamRemoved]);

  return (
    <TeamsMenuContainer withSubmit={withSubmit} full={full}>
      <div className="teams-options-container">
        <TeamsMenuItem
          key={noTeamOption}
          type={'button'}
          onClick={() => handleTeamSelection(noTeamOption)}
        >
          <Checkbox style={{ marginRight: '10px' }} checked={selected.length === 0} controlled />
          {analyticsTheme ? (
            <IconTeam size={'smaller'} fallbackIcon={account?.logo_url} />
          ) : (
            <IconContainer size={'smaller'}>
              <span className="remove-team">
                <Icon icon={'Block'} />
              </span>
            </IconContainer>
          )}
          <span className={'teams-label'}>{selectAllLabel}</span>
        </TeamsMenuItem>
        <Divider style={{ margin: 0, width: 'calc(100% - 10px)', minWidth: 'auto' }} />
        {accountTeams?.map((item) => (
          <Fragment key={item.id}>
            <TeamsMenuItem
              key={item.id}
              type={'button'}
              onClick={() => handleTeamSelection(item.id)}
            >
              <Checkbox
                style={{ marginRight: '10px' }}
                checked={selected.length === 0 ? item.id === '' : selected.includes(item.id)}
                controlled
              />
              <IconTeam
                iconName={item.icon?.name}
                color={item.icon?.color}
                size={'smaller'}
                fallbackIcon={account?.logo_url}
              />
              <span className={'teams-label'}>{item.name}</span>
            </TeamsMenuItem>
            <Divider style={{ margin: 0, width: 'calc(100% - 10px)', minWidth: 'auto' }} />
          </Fragment>
        ))}
      </div>
      {!withSubmit && (
        <TeamsDropdownCTA>
          <Button $secondary onClick={handleClose}>
            Cancel
          </Button>
          <Button $primary disabled={!selected} onClick={handleTeamChangeSubmit}>
            Update team
          </Button>
        </TeamsDropdownCTA>
      )}
    </TeamsMenuContainer>
  );
};

const TeamsSelector = ({
  onTeamChange,
  withSubmit,
  accountTeams,
  withEditBtn = true,
  emptyLabel = 'Unassigned team',
  editBtnRight,
  maxWidth,
  userTeams,
}: TeamsSelectorProps): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const { account } = useContext(SessionContext);

  const handleTeamChange = (teamsSelected: string[]) => {
    onTeamChange(teamsSelected);
  };

  return (
    <CustomDropdown
      maxWidth={maxWidth}
      overlayStyle={{ maxWidth: 'calc(100% - 20px)', margin: '0 20px' }}
      overlay={
        <TeamsMenu
          teamsSelected={userTeams}
          accountTeams={accountTeams}
          onClose={() => setVisible(false)}
          onTeamSelect={handleTeamChange}
          withSubmit={withSubmit}
          account={account}
          emptyLabel={emptyLabel}
        />
      }
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <div>
        {withEditBtn && (
          <Tooltip placement="bottomLeft" title={'Change user team'}>
            <FeatherIcon
              icon={'edit'}
              size="15"
              color="#A793F6"
              style={{ margin: editBtnRight ? '0 0 0 10px' : '0 10px 0 0' }}
            />
          </Tooltip>
        )}
      </div>
    </CustomDropdown>
  );
};

export default TeamsSelector;
