import './statics.scss';
import Checkbox from 'components/checkbox';
import { capitalize } from 'utils/helpers';
import { Tooltip } from 'antd';
import Icon from 'components/icons';
import { useMemo } from 'react';
interface FCProps {
  options: {
    key: string;
    label?: string;
    checked?: boolean;
    tooltipLabel?: string;
    disabled?: boolean;
    hideCheckbox?: boolean;
    notAllowed?: boolean;
    dashboardTooltip?: string;
  }[];
  toggleCheckbox: (value: string) => void;
  className: string;
  chartType?: string;
  isDashboardChart?: boolean;
}

const ChartDataSelector = ({
  options,
  toggleCheckbox,
  className,
  isDashboardChart,
  chartType,
}: FCProps): JSX.Element => {
  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  } as React.CSSProperties;

  const noCheckbox = useMemo(() => {
    return options.every((option) => option.hideCheckbox);
  }, [options]);

  const getTooltipText = (
    isDashboardChart: boolean,
    dashboardTooltip: string,
    tooltipLabel: string
  ) => {
    if (!isDashboardChart) {
      return tooltipLabel;
    }
    return dashboardTooltip ? dashboardTooltip : tooltipLabel;
  };

  return (
    <div
      className={`chart-data-selector ${
        noCheckbox ? 'chart-data-selector--no-checkbox' : ''
      } ${className}`}
    >
      {options.map(
        ({
          key,
          label,
          checked,
          tooltipLabel,
          disabled,
          notAllowed,
          hideCheckbox,
          dashboardTooltip,
        }) => {
          return (
            !disabled && (
              <div key={key} style={containerStyles}>
                {!hideCheckbox ? (
                  <Checkbox
                    key={key}
                    className={`chart-data-selector__option-${key} chart-data-selector__option-activities-${key}`}
                    checked={checked}
                    onChange={() => toggleCheckbox(key)}
                    controlled
                    label={label ? label : capitalize(key)}
                    disabled={notAllowed}
                  />
                ) : (
                  <div
                    className={`chart-data-selector__no-checkbox-option chart-data-selector__no-checkbox-option-${key}`}
                  >
                    {label ? label : capitalize(key)}
                  </div>
                )}
                {tooltipLabel && (
                  <Tooltip
                    title={getTooltipText(isDashboardChart, dashboardTooltip, tooltipLabel)}
                    placement="right"
                    overlayInnerStyle={{ textAlign: 'center' }}
                  >
                    <Icon
                      data-testid="info-inactive-icon"
                      icon="Info"
                      fill={'var(--lilac)'}
                      size="smaller"
                    />
                  </Tooltip>
                )}
              </div>
            )
          );
        }
      )}
    </div>
  );
};

export default ChartDataSelector;
