import { IconTeam } from 'components/teamSelect/IconTeam';
import { TEAMS_ENTITY, USERS_ENTITY } from 'constants/analytics';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { ITableCellData } from 'contexts/analytics/types';
import { randomColor } from 'utils/colors';
import { LetterAvatar, UserImage } from './styles';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import UserLicenseToggleWrapper from './UserLicenseToggleWrapper';
import { Skeleton } from 'antd';

const TableCellContent = ({
  index,
  item,
  column,
  entity,
  showValues,
  card,
  isLoading,
}: ITableCellData): JSX.Element => {
  const Wrapper = useMemo(() => {
    const id = typeof column?.id === 'function' ? column.id(item) : undefined;
    return id && index === 0 && entity === USERS_ENTITY.VALUE
      ? ({ children }) => (
          <NavLink to={`/user/${id}`} onClick={(e) => e.stopPropagation()} className="users-link">
            {children}
          </NavLink>
        )
      : React.Fragment;
  }, [index, entity, column]);

  const getActivityType = (key) => {
    const currentKey = key?.toLowerCase();
    switch (currentKey) {
      case 'lab skill dive':
        return 'Skill Dive lab';
      case 'lab standard lab':
        return 'Standard lab';
      default:
        return key;
    }
  };

  const cellsColumnWithNoTooltip = ['Last activity date', 'Started activities'];

  const renderValue = useMemo(() => {
    if (column.actionValue === 'license-toggle')
      return <UserLicenseToggleWrapper user={column.value(item)} isLoading={isLoading} />;
    if (isLoading) return null;
    const { content_type } = item;
    const value = column.value(item);
    if (value === undefined) return null;
    const valueLimit = 100;
    if (content_type !== 'course' && content_type !== 'learningpath') return value;

    const valueToReturn = value.length > valueLimit ? value.slice(0, valueLimit) + '...' : value;

    if (cellsColumnWithNoTooltip.includes(column.label)) {
      return valueToReturn;
    }

    return (
      <Tooltip title={value} placement="bottom" overlayInnerStyle={{ textAlign: 'center' }}>
        {valueToReturn}
      </Tooltip>
    );
  }, [item, isLoading]);

  const contentBorderColor = useMemo(() => {
    const { activity_type } = item;
    if (!activity_type) return '';
    const type = activity_type.toLowerCase().replace(/\s+/g, '-');
    return `content-${type}`;
  }, [item]);

  const multipleClass = column.multiline ? `column-multiline` : '';

  return (
    <Wrapper>
      <div
        className={'entities-table__table-body-row'}
        style={{ display: isLoading ? 'none' : 'flex' }}
      >
        {index === 0 &&
          (entity === USERS_ENTITY.VALUE ? (
            item?.user?.avatar && item?.user?.avatar !== DEFAULT_IMG_USERS ? (
              <UserImage src={item?.user?.avatar} />
            ) : (
              item && (
                <LetterAvatar color={randomColor(item?.user?.email)} card={card}>
                  {column.value(item)[0]}
                </LetterAvatar>
              )
            )
          ) : entity === TEAMS_ENTITY.VALUE ? (
            item?.team?.icon_name && item?.team?.icon_color ? (
              <IconTeam
                iconName={item?.team?.icon_name}
                color={item?.team?.icon_color}
                size={'small'}
                className="entities_icon_container"
              />
            ) : (
              <IconTeam
                iconName="group"
                color="blue"
                size={'small'}
                className={card && 'card-icon-container'}
              />
            )
          ) : (
            <></>
          ))}
        {(index === 0 || showValues) && item && (
          <div className={`${column.name}__value`}>
            {column?.activityType && (
              <Tooltip
                title={getActivityType(column.activityType(item))}
                placement="bottom"
                overlayInnerStyle={{ textAlign: 'center' }}
              >
                <div
                  className={'column-border'}
                  style={{ backgroundColor: `var(--${contentBorderColor})` }}
                ></div>
              </Tooltip>
            )}
            <div className={`${multipleClass}`}>
              {renderValue}
              {column.scndValue && item && (
                <div className={column.name + '__scnd'}>{column.scndValue(item)}</div>
              )}
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <Skeleton active={true} paragraph={{ rows: 1 }} title={false} loading={true}></Skeleton>
      )}
    </Wrapper>
  );
};

export default TableCellContent;
