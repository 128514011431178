import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COLOR_CHART_STATUSES } from 'constants/analytics';
import { IFullCHartData } from 'contexts/analytics/types';
import { useContext, useMemo } from 'react';
import { durationFromSecs } from 'utils/helpers';
import { CHART_TYPES_LABELS } from 'constants/analytics';
import { SessionContext } from 'contexts/session';

interface Props {
  chartType: string;
  chartDataType?: string;
  items: IFullCHartData[];
  Xaxis: string;
  Yaxis: string;
  dataDisplay: {
    key: string;
    checked?: boolean;
    fixed?: boolean;
    fixedFor?: string[];
    disabled?: boolean;
  }[];
  formatXAxisLabel?: (data: string, index: number) => string;
}

const ActiveUsersChart = (data: Props): JSX.Element => {
  const { hasSkillDive } = useContext(SessionContext);

  const tooltipLabel = useMemo(() => {
    switch (data.chartDataType) {
      case 'videos_overall':
        return 'watched';
      default:
        return `total ${data?.chartDataType}`;
    }
  }, [data.chartDataType]);

  const customStyles = {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.200691)',
    borderRadius: '4px',
    width: 'fit-content',
    padding: '8px 16px',
    background: '#57575C',
    color: '#ffffff',
  };

  const dotStyles = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '9px',
    border: '1px solid #E9E9ED',
  };

  const dataNumberText = (value) => {
    if (['videos_overall'].includes(data.chartDataType))
      return durationFromSecs(value * 60, 'hhmm') || '0m';
    return value;
  };

  const labTypeLabel = (labType) => {
    switch (labType) {
      case 'skill_dive':
        return 'Skill Dive';
      case 'total':
        return '';
      default:
        return labType;
    }
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const currentLabel = label || payload[0]?.payload?.date;
      return (
        <div style={customStyles}>
          {payload.map((entry, index) => {
            let action;
            let tooltipCustomText;
            const value = dataNumberText(entry.value);
            const chartDataType = data?.chartDataType.toUpperCase();
            const type =
              entry.value !== 1
                ? CHART_TYPES_LABELS[chartDataType]?.PLURAL
                : CHART_TYPES_LABELS[chartDataType]?.SINGULAR;

            switch (chartDataType) {
              case 'USERS':
                action = `licensed ${type} were active`;
                break;
              case 'LABS': {
                action = `${labTypeLabel(entry.name)} ${type} initiated`;
                if (!hasSkillDive && entry.name === 'skill_dive') {
                  tooltipCustomText =
                    'Skill Dive is not enabled for your account; please contact your account manager to add this feature';
                }
                break;
              }
              case 'ACTIVITIES':
                action =
                  entry.name === 'count'
                    ? `${tooltipLabel}`
                    : `${entry.name === 'labs' ? 'hands-on labs' : entry.name}`;
                break;

              default:
                action = entry.name === 'count' ? `${tooltipLabel}` : `${type} ${entry.name}`;
                break;
            }

            const tooltipText = tooltipCustomText || `${value} ${action} on ${currentLabel}`;
            return (
              <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ ...dotStyles, backgroundColor: entry.stroke || entry.fill }}></span>
                <p style={{ margin: 0 }}>{tooltipText}</p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  // Custom Label Component for X-Axis
  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    const formattedDate = data?.items
      ? data.formatXAxisLabel
        ? data.formatXAxisLabel(payload.value, payload.index)
        : payload.value
      : '';
    const [line1, line2] = formattedDate.split('\n');

    return (
      <g transform={`translate(${x},${y})`}>
        {/* Apply custom styles here */}
        <text x={0} y={0} dy={10} textAnchor="middle" fill="#AEAEB2" fontSize="8">
          {/* Display line 1 */}
          {line1}
        </text>
        {line2 && (
          <text x={0} y={11} dy={10} textAnchor="middle" fill="#AEAEB2" fontSize="8">
            {/* Display line 2 (e.g., for the year in the monthly format) */}
            {line2}
          </text>
        )}
      </g>
    );
  };
  return (
    <>
      {data.chartType === 'BarChart' ? (
        <ResponsiveContainer width={'100%'} height={'90%'}>
          <BarChart
            width={500}
            data={data.items}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
            barGap={1.3}
          >
            <XAxis dataKey={data.Xaxis} tick={<CustomXAxisTick />} />
            <YAxis dataKey={data.Yaxis} />
            {data.chartDataType && <Tooltip content={customTooltip} shared={false} />}
            <CartesianGrid strokeDasharray="3 3" />
            {data.dataDisplay.map((bar) => {
              if (bar.checked || bar.fixed || bar.fixedFor?.includes(data.chartType)) {
                return (
                  <Bar
                    key={bar.key}
                    dataKey={
                      ['overall', 'watched', 'licensed', 'percentage'].includes(bar.key)
                        ? 'count'
                        : bar.key
                    }
                    stackId={bar.key}
                    fill={COLOR_CHART_STATUSES[bar.key.toUpperCase()].fill}
                  />
                );
              }
            })}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height={'90%'} minHeight={'90%'}>
          <AreaChart
            data={data.items}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="6 6" />
            <XAxis dataKey={data.Xaxis} tick={<CustomXAxisTick />} />
            <YAxis dataKey={data.Yaxis} />
            {data.chartDataType && <Tooltip content={customTooltip} />}
            {data.dataDisplay.map((area) => {
              if (!area.disabled && (area.checked || area.fixed)) {
                return (
                  <Area
                    key={area.key}
                    type="monotone"
                    dataKey={
                      ['overall', 'watched', 'licensed', 'percentage'].includes(area.key)
                        ? 'count'
                        : area.key
                    }
                    stroke={COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke}
                    fill={COLOR_CHART_STATUSES[area.key.toUpperCase()].fill}
                    strokeWidth={1}
                    isAnimationActive={false}
                    dot={(props) => {
                      const { cx, cy, value } = props;
                      if (value.length && value[0] === 0 && value[1] === 0) {
                        return null;
                      }
                      return (
                        <circle
                          cx={cx}
                          cy={cy}
                          r={3}
                          fill={COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke}
                          stroke={COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke}
                          strokeWidth={1}
                        />
                      );
                    }}
                    activeDot={{
                      cursor: 'pointer',
                      strokeWidth: 0,
                      stroke: COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke,
                    }}
                  />
                );
              }
            })}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ActiveUsersChart;
