import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import UserCardLicense from '../../../Users/components/licenseCard';
import { UserProfileData } from '../../../Users/types';
import { Spinner } from 'components/loadings';
import Notification from 'components/notification';
import { getUserProfile } from 'services/userProfile';

const SpinnerContainer = styled.div`
  position: absolute;
  opacity: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ToggleContainer = styled.div`
  position: relative;
  width: 44px;
  height: 28px;
  display: flex;
  align-items: center;
`;

type Props = {
  user: any;
  isLoading: boolean;
};

const UserLicenseToggleWrapper = ({ user, isLoading }: Props): JSX.Element => {
  const [userData, setUserData] = useState<UserProfileData>(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isLoading && userData === null) getUserData();
  }, [user.business_id, isLoading]);
  const getUserData = async () => {
    setIsFetching(true);
    try {
      const response = await getUserProfile(user.business_id);
      if (response.status === 200) {
        const data = response.data;
        if (response.status) {
          setUserData(data);
        }
      }
    } catch (error) {
      Notification({ text: 'Error retrieving user information', type: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const fetching = useMemo(() => isFetching || !userData?.user, [isFetching, userData]);

  return (
    <ToggleContainer>
      {!fetching && (
        <UserCardLicense
          user={userData?.user}
          user_invite={userData?.user_invite}
          onlySwitch={true}
          onRefreshData={() => getUserData()}
          onSwitchData={(value) => setIsFetching(value)}
        />
      )}
      {fetching && (
        <SpinnerContainer>
          <Spinner active={true} size="25" />
        </SpinnerContainer>
      )}
    </ToggleContainer>
  );
};

export default UserLicenseToggleWrapper;
