import { Space, Tooltip } from 'antd';
import Switch from 'components/switch';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Notification from 'components/notification';
import { setUserLicense } from 'components/usersTable/actions';
import { SessionContext } from 'contexts/session';
import { UserProfileData } from 'pages/Users/types';
import { ACC_OWNER, ROLES, TEAM_MANAGER } from 'constants/roles';

const LicenseData = styled.div`
  display: flex;
  justify-content: space-between;
  .license-data__content {
    > h5 {
      margin-bottom: 0;
      font-size: 24px;
      margin-bottom: 10px;
    }
    > p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
`;

function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('.');
}

const LicenseCard = ({
  onRefreshData,
  onSwitchData,
  user,
  user_invite,
  onlySwitch,
}: {
  onRefreshData?: () => void;
  onSwitchData?: (value: boolean) => void;
  user: any;
  user_invite?: { id: string; role: string };
  onlySwitch?: boolean;
}): JSX.Element => {
  const license = user?.license;
  const assigned_at = license?.assigned_at ? new Date(license?.assigned_at) : '';
  const active_since = license?.active_since ? new Date(license?.active_since) : '';
  const initialIsLicense = !!license;
  const [isLicense, setIsLicense] = useState(initialIsLicense);

  const team = user?.team?.id;

  const {
    licenses_available,
    updateLicensesAvailables,
    allows,
    user: userSession,
    userRoleIds,
  } = useContext(SessionContext);

  const handleSwitchLicense = async ({ ids, checked }: { ids: string[]; checked: boolean }) => {
    setIsLicense(checked);
    onSwitchData(true);
    const response = await setUserLicense({ ids, license: checked });
    if (!(response instanceof Error) && response.result) {
      for (let index = 0; index < ids.length; index++) {
        updateLicensesAvailables(checked);
      }
      Notification({ text: 'License updated', type: 'success' });
      setIsLicense(checked);
      onRefreshData();
    } else {
      Notification({ text: 'License couldn’t be updated', type: 'error' });
      setIsLicense(!checked);
      onSwitchData(false);
    }
  };

  const isAccountOwner = user.uaa_data
    ? user.uaa_data.roles.data.find((role) => role.name === ACC_OWNER) !== undefined
    : user_invite?.role === ACC_OWNER;

  const isRoleManager = (team: string) => {
    return user_invite?.role === TEAM_MANAGER && Boolean(team !== 'no-team');
  };

  const isManager = isRoleManager(team || 'no-team');

  const { can_unnasign_license } = allows;
  const accOwnerLoggedIn = userRoleIds.includes(ROLES.ACCOUNT_OWNER);
  const teamManagerLoggedIn = userRoleIds.includes(ROLES.TEAM_MANAGER);

  const isLicenseSwitchDisabled =
    (accOwnerLoggedIn &&
      ((user.uaa_id
        ? isAccountOwner && user.uaa_id !== userSession.uaa_id
        : isAccountOwner && user.email !== userSession.email) ||
        !licenses_available ||
        !can_unnasign_license)) ||
    (teamManagerLoggedIn &&
      ((user.uaa_id
        ? isManager && user.uaa_id !== userSession.uaa_id
        : isManager && user.email !== userSession.email) ||
        isAccountOwner ||
        !licenses_available ||
        !can_unnasign_license));

  useEffect(() => {
    setIsLicense(!!license);
  }, [license]);

  const tooltipLabel = useMemo(() => {
    if (!onlySwitch) return '';
    if (!license) return 'Assign License';
    const hasSkillDive = user?.license_addons?.length > 0;
    return hasSkillDive
      ? `If the user's plan license is removed, the Skill Dive license will also be removed`
      : 'Remove License';
  }, [license]);

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <LicenseData>
        {!onlySwitch && (
          <div className="license-data__content">
            <h5>License</h5>
            <p>
              {license?.assigned_at
                ? `Assigned: ${formatDate(assigned_at)}`
                : 'No license assigned'}
            </p>
            <p>
              {license?.assigned_at
                ? `Active since: ${formatDate(active_since)}`
                : 'No license active'}
            </p>
          </div>
        )}
        <Tooltip title={tooltipLabel} overlayInnerStyle={{ textAlign: 'center' }}>
          <Switch
            className="license-data__switch"
            disabled={isLicenseSwitchDisabled}
            defaultChecked={isLicense}
            checked={isLicense}
            onChange={(checked: boolean) => handleSwitchLicense({ ids: [user_invite.id], checked })}
          />
        </Tooltip>
      </LicenseData>
    </Space>
  );
};

export default LicenseCard;
