import { Tooltip as AntdTooltip, TooltipProps } from 'antd';

const Tooltip = (props: TooltipProps): JSX.Element => {
  const { children, overlayInnerStyle, ...rest } = props;

  const customStyles = {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.200691)',
    borderRadius: '4px',
    padding: '8px 16px',
    maxWidth: 'unset',
    ...overlayInnerStyle,
  };

  return (
    <AntdTooltip overlayInnerStyle={customStyles} color={'#57575C'} {...rest}>
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
