import {
  IPlaylist,
  IPlaylistGroup,
  IPlaylistUserDetails,
  IPlaylistUserListItemResponse,
  IPlaylistsDataCtx,
} from './types';

export const playlistsInitialValues: IPlaylistsDataCtx = {
  list: {
    items: null,
    loading: false,
    count: 0,
    error: 'false',
  },
  filters: {
    page: '1',
    page_size: '9',
    name: '',
    type: '',
    assigned: '',
  },
};

export const playlistInitialValues: IPlaylist = {
  id: null,
  name: '',
  color: '',
};

export const playlistUsersInitialValues: IPlaylistUserListItemResponse = {
  user: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    avatar: '',
  },
  last_activity_date: '',
  progress: 0,
  items_started: 0,
  items_finished: 0,
  items_total: 0,
  trackable_items_finished: 0,
  trackable_items_total: 0,
};
