import { screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #e9e9ed;
  border-radius: 10px;
  padding: 10px;
  flex-grow: 1;
  height: 90px;
  :nth-child(1) {
    grid-column: span 2;
  }
  :nth-child(2) {
    order: 3;
  }
  :not(:first-child) {
    height: 105px;
  }
  ${screenSm} {
    width: 172px;
    flex-grow: 0;
    :not(:first-child) {
      height: 90px;
    }
    :nth-child(2) {
      order: initial;
    }
  }
`;

export const CardQuantity = styled.span`
  font-weight: 700;
  font-size: 42px;
  color: #1a2535;
  text-align: center;
  line-height: normal;

  ${screenSm} {
    line-height: 38px;
  }
`;
export const Label = styled.label<{ color: string; hideDot?: boolean; index?: number }>`
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #57575c;
  display: flex;
  text-align: center;
  height: ${({ index }) => (index && index === 1 ? 'auto' : '32px')};
  ${screenSm} {
    height: auto;
    align-items: center;
  }
  &:before {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${({ color }) => color};
    display: ${({ hideDot }) => (hideDot ? 'none' : 'inline-block')};
    margin-right: 3px;
  }
`;

export const SpinnerContainer = styled.div`
  position: relative;
  opacity: 50%;
`;
