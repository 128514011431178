import { useContext, useState } from 'react';
import { Dropdown } from 'antd';
import { BodySubtitle, BodyTitle, InputTypeSelect, TeamPill } from '../styles';
import { TeamsMenu } from 'components/teamSelect';
import { SessionContext } from 'contexts/session';
import './styles.scss';
import { TeamIconData, UserTeam } from 'contexts/teams/types';
import Icon from 'components/icons';
import Switch from 'components/switch';

interface TeamProps {
  id: string;
  name: string;
  icon: TeamIconData;
}

interface TeamSelectProps {
  teamsSelected: UserTeam[];
  disabled?: boolean;
  onTeamChange: (props: TeamProps[]) => void;
  withManagerSwitch?: boolean;
  isManager?: boolean;
  analyticsTheme?: boolean;
  onSwitchChange?: (checked: boolean) => void;
  selectAllOption?: string;
  containerStyles?: Record<string, string>;
}

const TeamsSelect = ({
  teamsSelected,
  disabled,
  onTeamChange,
  withManagerSwitch,
  isManager,
  onSwitchChange,
  analyticsTheme,
  selectAllOption,
  containerStyles,
}: TeamSelectProps): JSX.Element => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [teamRemoved, setTeamRemoved] = useState(null);
  const { account, teams, isAccountOwner, isTeamManager } = useContext(SessionContext);

  const emptySelectionLabel = () => {
    if (selectAllOption) return selectAllOption;
    if (analyticsTheme) {
      return isAccountOwner ? 'All users from the account' : 'All my teams';
    }
    return 'No teams selected';
  };

  const oneTeamManager = isTeamManager && teams && teams.length === 1;

  const handleTeamChange = (teamIds: string[]) => {
    const newTeams = [];
    teamIds.map((teamId) => {
      const newTeam = teams?.filter(({ id }) => id === teamId)?.[0];
      newTeams.push({
        id: teamId,
        name: newTeam?.name,
        icon: newTeam?.icon || null,
      });
    });
    onTeamChange(newTeams);
  };

  const handleTeamRemove = (teamId: string) => {
    setTeamRemoved(teamId);
  };

  if (!teams) return null;
  if (oneTeamManager)
    teamsSelected = [
      {
        team: teams[0],
        team_id: teams[0].id,
        name: teams[0].name,
        is_manager: isTeamManager,
      },
    ];

  const SelectedTeamsPillsList = (): JSX.Element => {
    return (
      <>
        {teamsSelected.map((team) => (
          <TeamPill
            key={team.team?.id}
            closable={!oneTeamManager}
            onClose={() => handleTeamRemove(team.team?.id)}
            bgcolor={team.team?.icon?.color}
            closeIcon={<Icon icon={'Close'} size={'small'} />}
          >
            {team.team?.name}
          </TeamPill>
        ))}
      </>
    );
  };

  const stylesProp = containerStyles ?? {};

  return (
    <>
      {!analyticsTheme && <BodyTitle>Optional Settings</BodyTitle>}
      {!analyticsTheme && <BodySubtitle>Invite to team</BodySubtitle>}
      <div style={{ ...stylesProp, display: withManagerSwitch && 'flex' }}>
        <Dropdown
          overlay={
            <TeamsMenu
              analyticsTheme={analyticsTheme}
              accountTeams={teams}
              onClose={() => setMenuVisible(false)}
              onTeamSelect={handleTeamChange}
              withSubmit={true}
              account={account}
              emptyLabel={'Invite to account (Without team)'}
              selectAllOption={selectAllOption}
              fromInvites={true}
              teamRemoved={teamRemoved}
              full
            />
          }
          trigger={['click']}
          visible={menuVisible && !oneTeamManager}
          onVisibleChange={setMenuVisible}
          disabled={oneTeamManager}
          placement={'bottomRight'}
        >
          <InputTypeSelect
            onClick={() => setMenuVisible(!disabled)}
            style={{
              cursor: disabled ? 'not-allowed' : 'pointer',
              width: withManagerSwitch ? '80%' : '100%',
              gap: '5px',
            }}
          >
            <SelectedTeamsPillsList />
            <p>{teamsSelected.length === 0 && emptySelectionLabel()}</p>
            {!oneTeamManager && <Icon icon={'ChevronDown'} size={'smaller'} />}
          </InputTypeSelect>
        </Dropdown>
        {withManagerSwitch && (
          <Switch
            label={'Manager'}
            style={{ marginLeft: '12px' }}
            disabled={teamsSelected.length === 0}
            checked={isManager}
            onChange={(checked: boolean) => onSwitchChange(checked)}
          />
        )}
      </div>
    </>
  );
};

export default TeamsSelect;
