import { createContext } from 'react';
import { FCProps } from 'types/FCProps';
import { AnalyticsDataCtx } from './types';
import { analyticsInitialValues } from './utils';

import useAnalyticsContent from 'hooks/useAnalyticsContent';

export const AnalyticsContext = createContext<AnalyticsDataCtx>(analyticsInitialValues);

const AnalyticsProvider = ({ children }: FCProps): JSX.Element => {
  const {
    activitiesChart,
    activeUsersChart,
    entitiesTable,
    counters,
    fetching,
    fetchingCounters,
    fetchingTabCounters,
    filters,
    currentChart,
    updateChart,
    updateEntity,
    changeFilters,
    changePagination,
    changeOrdering,
  } = useAnalyticsContent();

  const actions = {
    onUpdateChart: updateChart,
    onUpdateEntity: updateEntity,
    onChangeFilters: changeFilters,
    onChangePagination: changePagination,
    onChangeOrdering: changeOrdering,
  };

  return (
    <AnalyticsContext.Provider
      value={{
        activities: activitiesChart,
        activeUsers: activeUsersChart,
        entitiesTable,
        counters,
        fetching,
        fetchingCounters,
        fetchingTabCounters,
        filters,
        currentChart,
        actions,
      }}
    >
      <>{children}</>
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
