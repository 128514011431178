import { screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const TabsContainer = styled.div<{ skillTabs?: boolean }>`
  display: flex;
  background-color: #f2f2f7;
  padding: 10px 10px 0 10px;
  justify-content: space-evenly;
  width: 100%;

  ${({ skillTabs }) => skillTabs && 'padding-left: 15px !important; justify-content: start;'};

  @media (max-width: 450px) {
    ${({ skillTabs }) => skillTabs && 'padding-left: 0px !important; justify-content: start;'};
  }

  .ant-dropdown-trigger {
    background-color: #ffffff !important;
    width: 100%;
    justify-content: space-between;
    border-radius: 10px;
    margin: 0 10px 10px;
    padding: 15px 30px;

    ${screenSm} {
      display: none;
    }
  }
`;
export const TabButton = styled.div<{
  active: boolean;
  loading?: boolean;
  height?: string;
  alignLeft?: boolean;
  padding?: string;
}>`
  width: 100%;
  cursor: ${({ loading }) => (loading ? 'not-allowed' : 'pointer')};
  display: none;
  background-color: ${({ active }) => (active ? '#FFFFFF' : 'transparent')};
  padding: ${({ padding }) => (padding ? padding : '15px 30px')};
  height: ${({ height }) => (height ? height : 'auto')};
  justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'center')};
  align-items: center;
  border-radius: 10px 10px 0 0;

  ${screenSm} {
    display: flex;
  }
`;

export const TabWrapper = styled.div<{ displayTabsValues?: boolean }>`
  display: flex;
  ${({ displayTabsValues }) => !displayTabsValues && 'align-items: center;'}
  justify-content: space-between;
  flex-grow: 0.05;
`;
export const TabIcon = styled.img`
  flex: 0;
`;
export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  ${screenSm} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const DataNumber = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #1a2535;
`;

export const DataDescription = styled.div<{ bigText?: boolean }>`
  ${({ bigText }) =>
    bigText
      ? 'font-size: 16px;font-weight: 500; color: var(--black-dark);line-height: 23px;'
      : 'font-size: 12px;font-weight: 450; color: var(--light-gray-darker);line-height: 14px;'}
`;

export const IconWrapper = styled.div<{ noBackground?: boolean }>`
  position: relative;
  margin-right: 10px;
  border-radius: 50px;
  ${({ noBackground }) => (noBackground ? 'background: none;' : 'background: #ffffff;')}
  line-height: 0;
  display: flex;
  width: 28px;
  height: 28px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
