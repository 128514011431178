import { screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const UsersRowContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const UserRow = styled.div<{
  disabled?: boolean;
  columns: string;
  transparent?: boolean;
  top?: boolean;
  topSelect?: boolean;
}>`
  height: 70px;
  align-items: center;
  padding: 0 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.02);

  ${screenSm} {
    background: transparent;
    padding: 10px;
    margin: 0;
  }

  ${({ transparent }) =>
    transparent &&
    `
    background: transparent;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background: #f2f2f74a !important;
  `}

  ${({ top }) =>
    top &&
    `
    height: 56px;
    ${screenSm} {
      height: 45px;
      border: none;
    }
  `}

  ${({ topSelect }) =>
    topSelect &&
    `
    height: auto;
    padding: 4px;
    width: 200px;
  `}

  display: grid;
  grid-template-columns: ${({ columns }) => columns};
`;

export const ProgressContainer = styled.div<{
  visible: boolean;
  disabled?: boolean;
  transparent?: boolean;
  top?: boolean;
  topSelect?: boolean;
}>`
  ${({ transparent }) =>
    transparent &&
    `
    background: transparent;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background: #f2f2f74a !important;
  `}

  ${({ top }) =>
    top &&
    `
    height: 56px;
    ${screenSm} {
      height: 45px;
      border: none;
    }
  `}

  ${({ topSelect }) =>
    topSelect &&
    `
    height: auto;
    padding: 4px;
    width: 200px;
  `}

  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
`;
export const PlaylistTopicContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  align-items: center;

  padding: 20px 0 20px 20px;

  ${screenSm} {
    padding: 30px 0 30px 30px;
  }

  border-bottom: 1px solid #d0d2db;

  &:last-child {
    border-bottom: none;
    padding-bottom: none;
  }
`;

export const TopicTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1a2535;
  width: 100%;
`;

export const GroupTitleLearningPath = styled.div`
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  color: #1a2535;
  width: 100%;
  margin-bottom: 10px;
`;

export const ContentList = styled.ul`
  padding: 0;
  margin: 20px 0 0 0;
  width: 100%;
`;

export const RowContent = styled.li<{ embeddedContent?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #f2f2f7;
  box-sizing: border-box;

  ${screenSm} {
    gap: 5px;
    flex-direction: row;
    align-items: center;
  }
  &:last-child {
    border-bottom: none;
  }

  .content-type-tag-container {
    width: 100px;
  }

  padding: 30px 30px 30px 0;
  ${({ embeddedContent }) =>
    embeddedContent &&
    `
    padding: 10px 0;
  `}
`;

export const GroupItemTitle = styled.span`
  flex: 1;

  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
`;

export const LearningPathTopicContainer = styled.div`
  background: rgba(242, 242, 247, 0.3);
  border-left: 2px solid #fec3cb;

  padding: 10px 20px;

  ${screenSm} {
    padding: 10px 30px 10px 20px;
  }
`;
