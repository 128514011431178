/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleErrors } from 'contexts/helpers';
import {
  getActiveUsers,
  getAgregation,
  getCoursesTable,
  getLabs,
  getPreferredContent,
  getSeries,
  getTeamsLabsTable,
  getTeamsQuizzTable,
  getTeamsTable,
  getTeamsWithActivityActivitiesTable,
  getUsersLabsTable,
  getUsersQuizzTable,
  getUsersTable,
  getUsersWithActivityActivitiesTable,
  getUsersWithActivityTotal,
  getUsersWithActivityUsersTable,
} from 'services/reporting';
import {
  AnalyticsUserActiveTotalParams,
  AnalyticsUserTableParams,
  PreferredContentParams,
  UsersWithActivityUsersTableParams,
} from './types';

export const getUsersTableAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getUsersTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUsersTableQuizzAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getUsersQuizzTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getTeamsTableQuizzAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getTeamsQuizzTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUsersTableLabsAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getUsersLabsTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getTeamsTableLabsAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getTeamsLabsTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUsersTableActivitiesAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getUsersWithActivityActivitiesTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getTeamsTableActivitiesAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getTeamsWithActivityActivitiesTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getTeamsTableAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getTeamsTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getCoursesTableAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getCoursesTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getSeriesByTypeAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getSeries(params);
    return {
      results: Object.entries(data).map(([key, value]) => {
        return { date: key, count: value };
      }),
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getAgregationAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getAgregation(params);
    return {
      results: data['result'],
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getActiveUsersAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getActiveUsers(params);
    return {
      results: Object.entries(data).map(([key, value]) => {
        return { date: key, count: value };
      }),
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getLabsAction = async (
  params: AnalyticsUserTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getLabs(params);
    return { results: data };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getPreferredContentAction = async (
  params: PreferredContentParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getPreferredContent(params);
    return {
      results: Object.entries(data).map(([key, value]) => {
        return { name: key, count: value };
      }),
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUsersWithActivityUsersTableAction = async (
  params: UsersWithActivityUsersTableParams
): Promise<{ results: any } | Error> => {
  try {
    const { data } = await getUsersWithActivityUsersTable(params);
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUsersWithActivityTotalAction = async (
  params: AnalyticsUserActiveTotalParams
): Promise<{ results: any } | Error> => {
  try {
    const response = await getUsersWithActivityTotal(params);
    const { data } = response;
    return {
      results: data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
