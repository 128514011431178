import { useContext, useEffect, useState } from 'react';
import { AnalyticsContext } from 'contexts/analytics';
import { TeamsSelectContainer, TeamsSelector, TopContainer } from './styles';
import { SessionContext } from 'contexts/session';
import { TeamPill } from 'pages/Users/components/InviteUsers/styles';
import { getTeamsAction } from 'contexts/teams/actions';

const AnalyticsTeamsFilter = (): JSX.Element => {
  const { actions, filters } = useContext(AnalyticsContext);
  const { user, isAccountOwner } = useContext(SessionContext);
  const [TMWithOneTeam, setTMWithOneTeam] = useState(false);
  const [accountTeams, setAccountTeams] = useState([]);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [loading, setIsLoading] = useState(true);

  const teamOjbConstructor = (team) => ({
    team,
    team_id: team.team_id,
    name: team.name,
    is_manager: !!team.is_manager,
  });

  const getAccountTeamsData = async () => {
    const teamsData = await getTeamsAction({
      account: user?.account,
      paginate: 'false',
      deep_details: 'false',
    });
    if (!(teamsData instanceof Error)) {
      setAccountTeams(teamsData?.results);
      setIsLoading(false);
    }
  };

  const handleTeamsSelectionChange = (selectedTeamsIds) => {
    const teamsIds = [];
    const teams = [];
    if (selectedTeamsIds?.length > 0) {
      selectedTeamsIds.forEach((team) => {
        teamsIds.push(team.id);
        teams.push(teamOjbConstructor(team));
      });
    }
    filters.team_ids = teamsIds.join(',');
    setTeamsSelected(teams);
    actions && actions.onChangeFilters(Object.assign({}, filters));
  };

  useEffect(() => {
    let isMounted = true;
    const updateTeamsSelected = () => {
      if (isMounted) {
        setTeamsSelected(
          TMWithOneTeam ? [teamOjbConstructor(user.teams.find((team) => team.is_manager))] : []
        );
      }
    };

    updateTeamsSelected();

    return () => {
      isMounted = false;
    };
  }, [TMWithOneTeam, accountTeams, user.teams]);

  useEffect(() => {
    if (isAccountOwner) {
      getAccountTeamsData();
    } else {
      setTMWithOneTeam(user.teams.filter((team) => team.is_manager).length === 1);
      setIsLoading(false);
    }
  }, [isAccountOwner]);

  return (
    <div className="analytics-teams-filter">
      <TopContainer>
        <TeamsSelectContainer className="analytics-teams-filters__title">
          {TMWithOneTeam ? (
            <>
              <div className="analytics-teams-filters__filters">Filter by team:</div>
              <TeamPill
                key={
                  teamsSelected[0]?.team?.team_id
                    ? teamsSelected[0]?.team?.team_id
                    : teamsSelected[0]?.team?.id
                }
                bgcolor={
                  teamsSelected[0]?.team?.icon_color
                    ? teamsSelected[0]?.team?.icon_color
                    : teamsSelected[0]?.team?.icon?.color
                }
              >
                {teamsSelected[0]?.name}
              </TeamPill>
            </>
          ) : (
            <>
              <div className="analytics-teams-filters__filters" style={{ minWidth: '10%' }}>
                {!loading && 'Filter by teams'}
              </div>
              <TeamsSelector
                analyticsTheme
                teamsSelected={teamsSelected}
                onTeamChange={handleTeamsSelectionChange}
              />
            </>
          )}
        </TeamsSelectContainer>
      </TopContainer>
    </div>
  );
};

export default AnalyticsTeamsFilter;
