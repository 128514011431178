import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import AppReadyLoading from 'components/loadings/AppReadyLoading';
import Icon from 'components/icons';
import UserTeamCard from '../../components/teamCard';
import { Card as ProfileCard } from 'components/layout/profile/profileContent/styles';
import useWindowSize from '../../../../hooks/useWindowSize';
import { UserProfileData } from '../../types';
import { SessionContext } from 'contexts/session';
import { Team } from 'contexts/teams/types';

const SliderOuterWrapper = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 10%);
  scroll-behavior: smooth;
`;

const cardMaxWidth = 360;
const Card = styled(ProfileCard)`
  &.card-team {
    flex-grow: 2.1;
    flex-shrink: 2.1;
    min-width: ${`${cardMaxWidth}px`};
  }
`;

const ScrollButton = styled.div<{ show?: boolean }>`
  width: 24px;
  height: 100%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  svg path {
    stroke: #a793f6;
  }
  &.left-chevron {
    rotate: 180deg;
  }
`;

interface TeamSliderProps {
  userInvite: UserProfileData['user_invite'];
  teamsData: Team[];
  dashboard?: boolean;
  userTeamAccOwner?: boolean;
  isItself?: boolean;
}

const TeamSlider: FC<TeamSliderProps> = ({
  userInvite,
  teamsData,
  dashboard,
  userTeamAccOwner,
  isItself,
}) => {
  const { isTeamManager: managerLoggedIn, isAccountOwner: accountOwnerLoggedIn } =
    useContext(SessionContext);
  if (!teamsData) {
    return <AppReadyLoading />;
  }
  const [showScrollLeftBtn, setShowScrollLeftBtn] = useState<boolean>(false);
  const [showScrollRightBtn, setShowScrollRightBtn] = useState<boolean>(true);

  const { width: windowWidth } = useWindowSize();
  const teamsCount = teamsData.length;
  const showScrollButton = teamsCount > 3;
  const showSmallView = cardMaxWidth * teamsCount > windowWidth - windowWidth * 0.23;
  const handleScrollButton = (goRight: boolean) => {
    const elem = document.getElementById('teamsSlider');
    const offsetWidth = elem.offsetWidth;
    if (goRight) {
      elem.scrollLeft += cardMaxWidth;
    } else {
      elem.scrollLeft -= cardMaxWidth;
    }
    setShowScrollLeftBtn(elem.scrollLeft > 0);
    setShowScrollRightBtn(elem.scrollLeft < offsetWidth);
    if (showScrollLeftBtn && !showScrollRightBtn) setShowScrollRightBtn(true);
    if (!showScrollLeftBtn && showScrollRightBtn) setShowScrollLeftBtn(true);
  };
  const SliderContent = (): JSX.Element => {
    return (
      <>
        {teamsData.map((teamData, index) => (
          <Card
            className={'card-team'}
            data-testid="slider-card-team"
            key={`${teamData.name}-${index}`}
          >
            <UserTeamCard
              smallView={showSmallView}
              team={teamData}
              user_invite={userInvite}
              isManager={!!teamData.is_manager}
              userTeamAccOwner={userTeamAccOwner}
              showLinkTeam={accountOwnerLoggedIn ? true : managerLoggedIn && !!teamData.is_manager}
              dashboard={dashboard}
              isItself={isItself}
            />
          </Card>
        ))}
      </>
    );
  };

  const ScrollBtn = ({ rigthBtn }: { rigthBtn?: boolean }): JSX.Element => {
    return (
      <ScrollButton
        data-testid="slider-scroll-button"
        show={showScrollButton && (rigthBtn ? showScrollRightBtn : showScrollLeftBtn)}
        className={rigthBtn ? '' : 'left-chevron'}
        onMouseDown={() => handleScrollButton(rigthBtn)}
      >
        <Icon icon="ChevronRight" />
      </ScrollButton>
    );
  };

  return (
    <SliderOuterWrapper>
      <ScrollBtn />
      <SliderWrapper id="teamsSlider" data-testid="slider-wrapper">
        <SliderContent />
      </SliderWrapper>
      <ScrollBtn rigthBtn />
    </SliderOuterWrapper>
  );
};

export default TeamSlider;
