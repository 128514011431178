import { IPlaylist, IPlaylistUserListItemResponse } from 'contexts/playlists/types';
import { Row, Table, Top } from 'components/Table';

import { ContentTitle } from 'pages/Playlists/PlaylistDetail/styles';
import FeatherIcon from 'feather-icons-react';
import { HeaderLoading } from 'components/loadings';
import { SessionContext } from 'contexts/session';
import Tooltip from 'components/tooltip';
import UsersRow from './UsersRow';
import { colors } from 'constants/styles/colors';
import { isMobile } from 'react-device-detect';
import { screenMd } from 'constants/styles/breakpoints';
import styled from 'styled-components';
import { useContext } from 'react';

export const Container = styled.div<{ lock: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 140px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  ${screenMd} {
    padding-bottom: 0;
  }

  ${({ lock }) =>
    lock &&
    `
		opacity: 0.4;
		pointer-events: none;
	`}
`;

interface IUserTableParams {
  lock: boolean;
  playlistUsers: IPlaylistUserListItemResponse[];
  fetching: boolean;
  playlist: IPlaylist;
  pagination?: { count: number; page: number; page_size: number };
  removeUser: (user: IPlaylistUserListItemResponse) => void;
  isSonar: boolean;
}

const InfoIcon = (props) => (
  <FeatherIcon
    size={14}
    icon="info"
    color={colors.palette.Business_DataVizLilacLighter}
    style={{ marginLeft: '5px' }}
    {...props}
  />
);

const UsersTable: React.FC<IUserTableParams> = ({
  lock,
  playlist,
  playlistUsers,
  fetching,
  removeUser,
  isSonar,
}) => {
  let columnsTableConfig;
  if (isMobile) {
    columnsTableConfig = '6fr 6fr 40px';
  } else {
    columnsTableConfig = '6fr 3fr 3fr 3fr 3fr';
  }

  return (
    <Container lock={lock}>
      <Table>
        {!isMobile && (
          <Row
            columns={columnsTableConfig}
            transparent={isMobile}
            topSelect={isMobile}
            noCtaHeader={isSonar}
            top
          >
            {!isSonar ? <Top>User</Top> : <ContentTitle>Playlist Progress</ContentTitle>}
            <>
              <Top>
                Items completed
                <Tooltip
                  placement="top"
                  title="The completion of all trackable items"
                  overlayInnerStyle={{ textAlign: 'center' }}
                >
                  <InfoIcon />
                </Tooltip>
              </Top>
              <Top>
                Items started
                <Tooltip
                  placement="top"
                  title="The count of all items that have been started"
                  overlayInnerStyle={{ textAlign: 'center' }}
                >
                  <InfoIcon />
                </Tooltip>
              </Top>
              <Top>
                Last activity date
                <Tooltip
                  placement="top"
                  title="User’s latest interaction with platform"
                  overlayInnerStyle={{
                    textAlign: 'center',
                  }}
                >
                  <InfoIcon />
                </Tooltip>
              </Top>
            </>
          </Row>
        )}

        {playlistUsers?.map((playlistUser, index) => (
          <UsersRow
            key={index}
            playlist={playlist}
            item={playlistUser}
            removeUser={removeUser}
            isSonar={isSonar}
          />
        ))}
      </Table>
      <HeaderLoading portals active={fetching} />
    </Container>
  );
};

export default UsersTable;
